<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10 px-0">
      <h2 class="mb-3">Appraisal Form</h2>
    </div>
    <div class="col-2 col-md-2 px-0 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="onClosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <template v-if="!loadingForm">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Employee Information</h3>
          <table class="table table-bordered">
            <thead class="thead-grey">
              <tr>
                <th>EMPLOYEE NAME</th>
                <!-- <th>EMPLOYEE ID</th> -->
                <th>DESIGNATION</th>
                <th>DATE OF REVIEW</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ employeeName }}</td>
                <!-- <td>{{ employeeId }}</td> -->
                <td>{{ designation }}</td>
                <td>{{ dateOfReview }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section A: Roles and Responsibilities</h3>
          <p>
            List roles and responsibilities in order of priority. Score your own
            capability or knowledge in the following areas in terms of your
            current role requirements (1-3 = poor, 4-6 = satisfactory, 7-9 =
            good, 10 = excellent). If appropriate, bring evidence with you to
            the appraisal to support your assessment.
          </p>
          <!-- Plus button to add new row -->

          <!-- <button img  src="../../assets/img/add_row.png" alt="Add row" @click="addNewRow" >
                        <div class="tool-tip"></div>Add New Row</button> -->
          <!-- Table for Section A -->
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Evaluation Area (To be completed by Employee)</th>
                  <th>Projects (To be completed by Employee)</th>
                  <th>Score (To be completed by Employee)</th>
                  <th>Lead Score (To be completed by Lead)</th>
                  <th>Comments (To be completed by Lead)</th>
                  <!-- <th>Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <!-- Use v-for to render rows dynamically -->
                <tr v-for="(row, index) in sectionARows" :key="index">
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.evaluationArea"
                      disabled
                      rows="3"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.project"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.scoreByEmployee"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.leadScore"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.leadComments"
                      rows="3"
                      disabled
                    />
                  </td>
                  <!-- <td>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRow(index)"
                    />
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section B: Skills/Technologies</h3>
          <p>
            List the skills/technologies that the appraisee set out to achieve
            in the past 12 months (or the period covered by this appraisal) -
            against each skill/technology, the appraisee must enter the project
            that the evidence will be demonstrated and verified against.
          </p>
          <p>
            Score the performance against each objective (1-3 = poor, 4-6 =
            satisfactory, 7-9 = good, 10 = excellent)
          </p>
          <!-- Plus button to add new row -->
          <!-- <button img src="../../assets/img/add_row.png" alt="Add row" @click="addNewRowB">
        <div class="tool-tip"></div>Add New Row
    </button> -->
          <!-- Table for Section B -->
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Skill/Technology (To be completed by Employee)</th>
                  <th>Project (To be completed by Employee)</th>
                  <th>Evidence (To be completed by Employee)</th>
                  <th>Score (To be completed by Employee)</th>
                  <th>Lead Score (To be completed by Lead)</th>
                  <th>Comments (To be completed by Lead)</th>
                  <!-- <th>Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <!-- Use v-for to render rows dynamically -->
                <tr v-for="(row, index) in sectionBRows" :key="index">
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.skillTechnology"
                      disabled
                      rows="3"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.project"
                      disabled
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.evidence"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.employeeScore"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      class="form-control light-border"
                      v-model="row.leadScore"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control light-border"
                      v-model="row.leadComments"
                      disabled
                      rows="3"
                    />
                  </td>
                  <!-- <td>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRowB(index)"
                    />
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Section C -->
        <!-- {{sectionCResponses}} -->
        <div class="col-md-12 mb-4">
          <h3>Section C: Performance Review Questions</h3>
          <div
            class="table-container"
            style="overflow: auto; max-height: 300px"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Questions</th>
                  <th>Employee Response</th>
                  <th>Lead Response</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(question, index) in sectionCData"
                  :key="index"
                >
                  <tr>
                    <td :colspan="question.contents.length > 1 ? 3 : 2">
                      <strong>{{ question.tableDataHeader }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(content, contentIndex) in question.contents"
                    :key="contentIndex"
                  >
                    <td>{{ content }}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .employeeScore
                          "
                          :value="'Yes'"
                          :name="'employeeResponse' + index + contentIndex"
                          disabled
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .employeeScore
                          "
                          :value="'No'"
                          :name="'employeeResponse' + index + contentIndex"
                          disabled
                        />
                        No
                      </label>
                    </td>
                    <td v-if="question.contents.length > 1">
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'Yes'"
                          :name="'leadResponse' + index + contentIndex"
                          disabled
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'No'"
                          :name="'leadResponse' + index + contentIndex"
                          disabled
                        />
                        No
                      </label>
                    </td>
                    <td v-else>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'Yes'"
                          :name="'leadResponse' + index + contentIndex"
                          disabled
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          v-model="
                            sectionCResponses[index].responses[contentIndex]
                              .leadScore
                          "
                          :value="'No'"
                          :name="'leadResponse' + index + contentIndex"
                          disabled
                        />
                        No
                      </label>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section D: Explanation of High Scores</h3>
          <p>
            List and give an explanation of the points where you have given a
            high score.
          </p>
          <!-- Plus button to add new row -->
          <!-- <button img src="../../assets/img/add_row.png" alt="Add row" @click="addNewRowD">
        <div class="tool-tip"></div>Add New Row</button> -->
          <!-- Table for Section D -->
          <div class="table-container" style="overflow: auto">
            <table class="table table-bordered">
              <thead class="thead-grey">
                <tr>
                  <th>Section (To be completed by Employee)</th>
                  <th>Evaluation Area/Skill (To be completed by Employee)</th>
                  <th>Explanation (To be completed by Employee)</th>
                  <!-- <th>Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <!-- Use v-for to render rows dynamically -->
                <tr v-for="(row, index) in sectionDRows" :key="index">
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.skill"
                      disabled
                      rows="3"
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.evaluationArea"
                      disabled
                      rows="3"
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control light-border"
                      v-model="row.explanation"
                      disabled
                      rows="3"
                    />
                  </td>

                  <!-- <td>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRowD(index)"
                    />
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <h3 class="mb-3">Section E: Team Lead Comments</h3>
          <p>
            Provide comments on achievements, failures, areas needing
            improvement, and goals for the next year.
          </p>

          <div class="form-group">
            <label for="leadComments">Team Lead Comments:</label>
            <textarea
              id="leadComments"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.teamLeadComments"
              disabled
            ></textarea>
          </div>

          <div class="form-group">
            <label for="achievementsFailures">Achievements and Failures:</label>
            <textarea
              id="achievementsFailures"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.achievementsFailures"
              disabled
            ></textarea>
          </div>

          <div class="form-group">
            <label for="improvementNeeded">Improvement Needed:</label>
            <textarea
              id="improvementNeeded"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.improvementNeeded"
              disabled
            ></textarea>
          </div>

          <div class="form-group">
            <label for="goalsNextYear">Goals For Next Year:</label>
            <textarea
              id="goalsNextYear"
              class="form-control light-border"
              rows="5"
              v-model="sectionERows.goalsForNextYear"
              disabled
            ></textarea>
          </div>
        </div>

        <!-- <div class="col-md-12 mb-4"> 
          <h3 class="mb-3">
            *Need to complete the following section only after the completion of
            the appraisal form from both employee and Lead/employer level.
          </h3>

          <table class="table table-bordered">
            <thead class="thead-grey">
              <tr>
                <th>Questions</th>
                <th>Employee</th>
                <th>Lead</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Have you had discussion with your lead or employer about your
                  appraisal before signing off:
                </td>
                <td>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.employeeResponse"
                      value="Yes"
                      disabled
                    />
                    Yes
                  </label>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.employeeResponse"
                      value="No"
                      disabled
                    />
                    No
                  </label>

                  (To be completed by Employee)
                </td>
                <td>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.leadResponse"
                      value="Yes"
                      disabled
                    />
                    Yes
                  </label>
                  <label class="radio-inline">
                    <input
                      type="radio"
                      v-model="sectionERows.discussionWithLead.leadResponse"
                      value="No"
                      disabled
                    />
                    No
                  </label>

                  (To be completed by Lead)
                </td>
              </tr>
              <tr>
                <td>Assessment Completed and Sign Off by:</td>
                <td>
                  <div :class="{ 'text-muted': disableCertainParts }">
                    <p>
                      <strong>Signed By:</strong>
                      <span class="signed-by">
                        {{ employeeSignature.signedBy }}</span
                      >
                    </p>
                    <p>
                      <strong>Name:</strong
                      ><strong> {{ employeeSignature.name }}</strong>
                    </p>
                    <p>
                      <strong>Reason:</strong>
                      <strong>{{ employeeSignature.reason }}</strong>
                    </p>
                    <p>
                      <strong>Date and Time:</strong
                      ><strong> {{ employeeSignature.dateTime }}</strong>
                    </p>
                    <p>
                      <strong>Ref:</strong
                      ><strong> {{ employeeSignature.ref }}</strong>
                    </p>
                  </div>
                </td>
                <td>
                  <div :class="{ 'text-muted': disableCertainParts }">
                    <p>
                      <strong>Signed By:</strong>
                      <span class="signed-by">
                        {{ leadSignature.signedBy }}</span
                      >
                    </p>
                    <p>
                      <strong>Name:</strong>
                      <strong>{{ leadSignature.name }}</strong>
                    </p>
                    <p>
                      <strong>Reason:</strong>
                      <strong>{{ leadSignature.reason }}</strong>
                    </p>
                    <p>
                      <strong>Date and Time:</strong
                      ><strong> {{ leadSignature.dateTime }}</strong>
                    </p>
                    <p>
                      <strong>Ref:</strong
                      ><strong> {{ leadSignature.ref }}</strong>
                    </p>
                  </div>
                  (To be completed by Lead)
                </td>
              </tr>
            </tbody>
          </table>
     </div> -->
      </div>
    </template>

    <!-- Submit buttons -->
    <div class="col-12 text-center my-2">
      <!-- <span type="button" tabindex="0" class="submit_btn px-5 py-2 mr-2" disa @click="showPopupvalid('TL_Submit')">Save &amp; Sign</span> -->
      <span
        type="button"
        tabindex="0"
        class="submit_btn px-5 py-2"
        v-if="!disableSaveButton"
        @click="verify('Save')"
      >
        <span v-if="loadingSaveBlock && !errorMsg">
          <img
            src="../../assets/img/loading.webp"
            width="20"
            class="loading-img mr-2"
            alt="Login buffering"
          />
        </span>
        Verify
      </span>
    </div>
  </div>
  <div class="password-popup" v-if="showpopup">
    <div class="modal-width">
      <div class="modal-content">
        <div class="password-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Sign Document</h5>
          <span
            type="button"
            class="align-self-center"
            @click="closePopup()"
            aria-hidden="true"
          >
            <i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <form>
            <div class="form-group mb-4">
              <h5>
                !!!On signing, this is equivalent to the wet ink signature!!!
              </h5>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                required=""
                v-model="emailId"
              />
            </div>
            <div class="form-group mb-4 position-relative">
              <input
                placeholder="Please enter your Digi-sign password"
                class="form-control"
                id="templatePassword"
                name="password"
                v-model="templatePassword"
              />
              <i
                class="password-visible-block cursor-pointer position-absolute"
                :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
              ></i>
            </div>
            <div class="form-group mb-4">
              <label class="mb-0" for="reasons">Reason</label>
              <select
                class="form-control"
                name="account"
                v-model="reasons"
                id="reasons"
              >
                <option value="" disabled selected>-Select Reason-</option>
                <option
                  v-for="reason in reasonList"
                  :key="reason.id"
                  :value="reason.reason"
                >
                  {{ reason.reason }}
                </option>
              </select>
              <input
                class="form-control mt-2"
                v-if="reasons === 'Others'"
                maxlength="50"
                v-model="otherReason"
                type="text"
                name="reason"
              />
            </div>

            <div class="text-center">
              <span
                type="button"
                tabindex="0"
                class="submit_btn px-5 py-2"
                :disabled="loadingBlock"
                @click.prevent="saveData('TL_Submit')"
              >
                <span v-if="loadingBlock && !errorMsg">
                  <img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img mr-2"
                    alt="Login buffering"
                  />
                </span>
                <span v-else>Submit</span>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./HrAssesment"></script>

<style scoped>
/* Add your component-specific styles here */
.light-border {
  border-color: #cccccc; /* Light gray color */
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.thead-grey {
  background-color: hsl(0, 8%, 95%);
}

.add-row-button {
  background-color: hsl(0, 8%, 95%);
  color: hsl(219, 60%, 61%);
}

.add-row-button:focus,
.add-row-button:active {
  border: 1px solid black;
}

.exception-header {
  background-color: hsl(7, 84%, 68%);
  color: hsl(0, 0%, 95%);
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.password-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.password-visible-block {
  right: 11px;
  top: 10px;
}

.errorMsg {
  color: hsl(0, 100%, 50%);
}

.border-red {
  border-color: hsl(0, 100%, 50%);
}

.modal-width {
  width: min(400px, 90%);
}

.table-side-block {
  text-align: center;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(150px, 90%);
  -moz-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  -webkit-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.table-side-block > div {
  padding: 0.5rem;
}

.table-side-block > div:hover {
  background-color: #e4eaf9;
}
.loading-img {
  mix-blend-mode: lighten;
}
.submit_btn[disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}
.add-row-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.add-row-block:hover .tool-tip,
.add-row-block:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.add-row-block img {
  width: 20px;
}
.height-40 {
  height: 34px;
}
/* Disable password visibility icon in Microsoft Edge */
input[type="password"]::-ms-reveal {
  display: none;
}
.input-skeleton:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  background: linear-gradient(270deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s linear infinite;
}
.signed-by {
  font-family: "Brush Script MT", cursive;
  font-size: 1.5em;
}
div p {
  margin-bottom: 4px; /* Adjust this value to decrease the spacing */
}
</style>
