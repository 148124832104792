<template>
  <div>
    <div class="ibox-title style_2 d-flex justify-content-between">
      <h5>Approve Assessment</h5>

      <div class="configure-block"></div>
    </div>
    <div class="form-table-view-container">
      <div class="table-responsive">
        <table class="table table-bordered training-plan">
          <thead>
            <tr>
              <th>Document Type</th>
              <!-- <th>Employee ID</th> -->
              <!-- <th>Designation</th> -->
              
              <th>Date of Review</th>
              <th>Employee</th>
               <th>Status</th>
              <th>Team lead</th>
              <th> Status</th>
              <th>Secondary Team lead</th>

              <th>Status</th>
              <th>HR Status</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody v-if="assessmentData">
            <tr
              v-for="(assessment, index) in assessmentData"
              :key="assessment.item.id"
            >
              <td>{{ "Assessment" }}</td>
              
              <td>{{ assessment.item.empdetailsTable.dateOfReview }}</td>
              <td>{{ assessment.name }}</td>
                <td>
                {{ assessment.item.totalStatus?.emp?.status ?? "Pending" }}
              </td>
              <td>{{ assessment.teamLead }}</td>
              <td>
                {{ assessment.item.totalStatus.lead?.status ?? "Pending" }}
              </td>
              <td>
                {{ assessment.secondaryTL ? assessment.secondaryTL : "NIL" }}
              </td>
              <td>
                {{
                  assessment.item.empdetailsTable.secondaryTeamLead
                    ? assessment.item.totalStatus &&
                      assessment.item.totalStatus.secondaryLead
                      ? assessment.item.totalStatus.secondaryLead.status
                      : "Pending"
                    : "NIL"
                }}
              </td>
              <td>{{ assessment.item.totalStatus.hr ?? "Pending" }}</td>

              <td class="text-center">
                <div
                  class="position-relative cursor-pointer"
                  @click.prevent="showSidePopup[index] = !showSidePopup[index]"
                  v-on:clickout="showSidePopup[index] = false"
                >
                  <i class="fa fa-ellipsis-v"></i>
                </div>
                <div class="plan-side-popup" v-if="showSidePopup[index]">
                  <!-- Edit action -->
                  <div
                    v-if="canEditAssessment(assessment)"
                    @click.prevent="editAssessment(assessment.item.id)"
                  >
                    Edit
                  </div>

                  <!-- Download action -->
                  <div
                    v-else-if="
                      assessment.item.totalStatus &&
                      assessment.item.totalStatus.hr === 'Verified'
                    "
                    @click="downloadAssessment(assessment.item.id)"
                  >
                    Download
                  </div>

                  <!-- Nothing to show -->
                  <div v-else>Nothing to show</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-3 mx-0">
      <div class="col-md-12 mx-0 px-0">
        <div
          class="dataTables_paginate paging_simple_numbers pagination float-right"
        >
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <VPagination
              v-model="currentPage"
              :pages="totalPages"
              @update:modelValue="loadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./TlAssesment"></script>

<style scoped>
.ref-label {
  font-size: 0.85em;
}

.signed-by {
  font-family: "Brush Script MT", cursive;
  font-size: 1.5em;
}

.training-table-view {
  display: inline-block;
  text-align: left;
  width: auto;
  color: gray;
  border-collapse: collapse;
  background-color: white;
  margin: 0 auto;
}

.training-table-view td,
th {
  text-align: left;
  border: 1px solid black;
  padding: 10px;
}

.icon-tableview {
  width: 12rem;
  height: 4rem;
  margin-right: 10px;
}

.heading-tableview h2 {
  text-align: center;
  flex: 1;
  font-weight: bold !important;
}

.date-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-search input {
  flex: 1;
  margin-right: 10px;
}

.date-search button {
  flex: 1;
  margin-right: 10px;
}

#cardtrainingBody {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: height 0.35s ease;
}

.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(109px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}

.training-plan thead tr th {
  text-wrap: balance !important;
  vertical-align: middle;
}

.training-plan tr td:nth-child(2),
.training-plan tr td:nth-child(3) {
  max-width: 40ch;
  word-wrap: break-word;
  white-space: break-spaces;
}
training-plan thead tr th {
  background-color: hsl(0, 0%, 100%) !important;
}
.create-training-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.training-plan thead th {
  font-weight: bold;
}

.imp {
  color: #ff000094;
  font-size: 7px;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.filter-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.filter-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.more {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}

.less {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}
.date-range {
  white-space: pre-line;
}
</style>
