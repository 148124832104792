<template>
  <div class="col-md-12 mb-4">
    <div style="position: relative">
      <h3>Manage Section C</h3>
      <i
        class="fa fa-times close cursor-pointer"
        @click="onClosed()"
        style="position: absolute; right: 0; top: 0; cursor: pointer"
      ></i>
    </div>
    <hr />

    <h4>Section C: Performance Review Questions</h4>
    <div class="table-responsive" style="max-height: 400px; overflow-y: auto">
      <table class="table table-bordered training-plan">
        <thead>
          <tr>
            <th>Questions</th>
            <th>Edit</th>
            <th>Delete</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(question, questionIndex) in sectionCData"
            :key="questionIndex"
          >
            <tr>
              <td
                class="question-cell"
                style="
                  word-wrap: break-word;
                  max-width: 1%;
                  white-space: normal;
                "
              >
                <template v-if="editQuestionIndex !== questionIndex">
                  <strong>{{ question.tableDataHeader }}</strong>
                </template>
                <template v-else>
                  <input
                    type="text"
                    v-model="question.tableDataHeader"
                    placeholder="Enter main question"
                  />
                </template>
              </td>
              <td>
                <button @click="editMainQuestion(questionIndex)">
                  {{
                    editQuestionIndex === questionIndex &&
                    editContentIndex === null
                      ? "Save"
                      : "Edit"
                  }}
                </button>
              </td>
              <td class="text-center">
                <img
                  class="cursor-pointer"
                  src="../../assets/img/delete.png"
                  alt="Delete"
                  @click="deleteMainQuestion(questionIndex)"
                />
              </td>
              <td>
                <button @click="addingSubQuestionIndex = questionIndex">
                  +
                </button>
              </td>
            </tr>
            <tr
              v-if="
                addingSubQuestionIndex === questionIndex && !editQuestionIndex
              "
            >
              <td colspan="4">
                <input
                  type="text"
                  v-model="newSubQuestion"
                  placeholder="Enter new sub-question"
                  ref="newSubQuestionInput"
                />
                <span
                  v-if="showSubQuestionError && !newSubQuestion"
                  style="color: red"
                  >Sub-question is required</span
                >
                <button
                  @click="saveNewSubQuestion(questionIndex)"
                  style="margin-left: 10px"
                >
                  Save
                </button>
                <button
                  @click="addingSubQuestionIndex = null"
                  style="margin-left: 10px"
                >
                  Cancel
                </button>
              </td>
            </tr>
            <tr
              v-for="(content, contentIndex) in question.contents"
              :key="contentIndex"
            >
              <td
                class="content-cell"
                style="
                  word-wrap: break-word;
                  max-width: 1%;
                  white-space: normal;
                "
              >
                <template
                  v-if="
                    editContentIndex === contentIndex &&
                    editQuestionIndex === questionIndex
                  "
                >
                  <input
                    type="text"
                    v-model="question.contents[contentIndex]"
                    placeholder="Enter sub-question"
                  />
                </template>
                <template v-else>
                  {{ content }}
                </template>
              </td>
              <td>
                <button @click="editSubQuestion(questionIndex, contentIndex)">
                  {{
                    editContentIndex === contentIndex &&
                    editQuestionIndex === questionIndex
                      ? "Save"
                      : "Edit"
                  }}
                </button>
              </td>
              <td class="text-center">
                <img
                  class="cursor-pointer"
                  src="../../assets/img/delete.png"
                  alt="Delete"
                  @click="deleteSubQuestion(questionIndex, contentIndex)"
                />
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <h4>Add New Main Question</h4>
      <div style="display: inline-block">
        <label>Main Question:</label>
        <input
          type="text"
          v-model="newMainQuestion"
          placeholder="Enter main question"
          style="width: 300px"
        />
        <span
          v-if="showMainQuestionError && !isValidMainQuestion()"
          style="color: red"
          >Main question is required</span
        >
      </div>
      <button
        class="btn btn-primary mt-"
        style="display: inline-block; margin-left: 10px"
        @click="addNewMainQuestion()"
      >
        Add
      </button>
    </div>

    <div class="mt-4">
      <button class="btn btn-success" @click="updateAssessmentTemplate">
        Update Template
      </button>
    </div>
  </div>
</template>

<style>
.question-cell,
.content-cell {
  max-width: 10%;
  word-wrap: break-word;
  white-space: normal;
}

.table td,
.table th {
  vertical-align: top;
}

.training-plan thead th {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}
</style>

<script src="./ManageAsesment"></script>
