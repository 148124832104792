
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";

export default {
  data() {
    return {
      loadingForm: false,
     
      baseurl: `${process.env.VUE_APP_Service_URL}`,
     
      sectionCData: [],
      employeeResponse: [],
      teamLeadResponse: [],
      userId: '', 
      assessmentData: {},
      showSubQuestionError: false,
      showMainQuestionError: false,
      newMainQuestion: '', 
      editQuestionIndex: null, 
      editContentIndex: null,
      newSubQuestion: '',
      addingSubQuestionIndex: null,
      assessmentTemplate: null,
      showpopup: false,
      inputType: "password", 
      loadingSaveBlock: false, 
      errorMsg: null 
    };
  },

  mounted() {
    this.idtoken = store.getters.getToken;
    try {
      const decodedToken = jwt_decode(this.idtoken);
      this.userId = decodedToken.user_id;
      this.getAssessmentTemplate();
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  },

  methods: {
    onClosed() {
      window.history.back();
    },
    async showPopupvalid(value) {
      this.inputType = value;
      if (this.inputType === 'TL_Submit') {
        this.showpopup = true;
        this.getReasons();
      }
    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn;
      this.inputType = this.visibleOn ? "text" : "password";
    },
    addNewRow() {
      this.sectionARows.push({
        evaluationArea: '',
        project: '',
        scoreByEmployee: '',
        leadScore: '',
        leadComments: ''
      });
    },
    isValidMainQuestion() {
      return this.newMainQuestion.trim() !== '';
    },
    editMainQuestion(index) {
      if (this.editQuestionIndex === index && this.editContentIndex === null) {
        // Save the main question
        this.editQuestionIndex = null;
      } else {
        // Edit the main question
        this.editQuestionIndex = index;
        this.editContentIndex = null;
      }
    },
    saveMainQuestion() {
      this.editQuestionIndex = null; 
    },
    deleteMainQuestion(index) {
      this.sectionCData.splice(index, 1); 
      this.updateMainQuestionNumbers(index); 
    },
    
    
    updateMainQuestionNumbers(startIndex) {
     
      for (let i = startIndex; i < this.sectionCData.length; i++) {
        const mainQuestion = this.sectionCData[i];
        const number = i + 1;
        const parts = mainQuestion.tableDataHeader.split('. ');
        if (parts.length > 1) {
          
          mainQuestion.tableDataHeader = `${number}. ${parts[1]}`;
        } else {
          
          mainQuestion.tableDataHeader = `${number}. ${mainQuestion.tableDataHeader}`;
        }
      }
    },
    
    
    
    editSubQuestion(questionIndex, contentIndex) {
      if (this.editContentIndex === contentIndex && this.editQuestionIndex === questionIndex) {
        
        this.editContentIndex = null;
      } else {
        
        this.editQuestionIndex = questionIndex;
        this.editContentIndex = contentIndex;
      }
    },
    saveSubQuestion() {
      this.editQuestionIndex = null; 
      this.editContentIndex = null; 
     
    },
    deleteSubQuestion(questionIndex, contentIndex) {
      const question = this.sectionCData[questionIndex];
      question.contents.splice(contentIndex, 1); 
      this.updateSubQuestionNumbers(questionIndex); 
    },
    
    updateSubQuestionNumbers(questionIndex) {
      const question = this.sectionCData[questionIndex];
      
      for (let i = 0; i < question.contents.length; i++) {
        const subQuestion = question.contents[i];
        const number = i + 1;
        const parts = subQuestion.split('. ');
        if (parts.length > 1) {
       
          question.contents[i] = `${number}. ${parts[1]}`;
        } else {
          question.contents[i] = `${number}. ${subQuestion}`;
        }
      }
    },
    addNewMainQuestion() {
    
      if (!this.isValidMainQuestion()) {
      
        this.showMainQuestionError = true;
        return; 
      }
      
  
      const nextNumber = this.sectionCData.length + 1;
      const newQuestionText = `${nextNumber}. ${this.newMainQuestion}`;
      const newQuestion = {
        tableDataHeader: newQuestionText,
        contents: [] 
      };
      this.sectionCData.push(newQuestion);
      this.newMainQuestion = ''; 
      this.showMainQuestionError = false; 
    },
    
    addNewSubQuestion(questionIndex) {
     
      this.addingSubQuestionIndex = questionIndex;
    },

    saveNewSubQuestion(questionIndex) {
    
      if (!this.newSubQuestion.trim()) {
      
        this.showSubQuestionError = true;
      
        this.$refs.newSubQuestionInput.focus();
        return; 
      }
    
      const question = this.sectionCData[questionIndex];
      const nextNumber = question.contents.length + 1;
      const newSubQuestionText = `${nextNumber}. ${this.newSubQuestion}`;
      question.contents.push(newSubQuestionText);
      this.newSubQuestion = ''; 
      this.addingSubQuestionIndex = null; 
      this.showSubQuestionError = false;
    },
    
  
    
    addNewQuestion() {
      if (this.newMainQuestion.trim() !== '') {
        const newQuestion = {
          tableDataHeader: this.newMainQuestion,
          contents: [] 
        };
        this.sectionCData.push(newQuestion);
        this.newMainQuestion = '';
      }
    },
   
   
    async getAssessmentTemplate() {
      try {
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmettemplate?Id=a850b5bf-5532-4d6e-9dd8-5adc1987bf20`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        this.sectionCData = response.data.tableList.find(item => item.tableHeader === "Performance Review Questions").tableData;
        this.employeeResponse = this.sectionCData.map(mainQuestion => {
          return mainQuestion.contents.map(() => '');
        });
        this.teamLeadResponse = this.employeeResponse.map(mainQuestion => {
          return mainQuestion.map(() => '');
        });
      } catch (error) {
        console.error("Error fetching assessment template:", error);
      }
    },
    toggleTableHeight() {
      const tableContainer = document.querySelector('.table-container');
      tableContainer.style.maxHeight = tableContainer.style.maxHeight === 'none' || !tableContainer.style.maxHeight
        ? '300px'
        : 'none';
    },
    closePopup() {
      this.showpopup = false;
    },
    async updateAssessmentTemplate() {
      try {
        if (!this.newSubQuestion.trim()) {
      
          this.showSubQuestionError = true;
        
          this.$refs.newSubQuestionInput.focus();
          return; 
        }
        const updateData = {
          TableList: [
            {
              TableHeader: "Performance Review Questions",
              TableData: this.sectionCData
            }
          ]
        };
        const id = "a850b5bf-5532-4d6e-9dd8-5adc1987bf20";
        const response = await axios.put(`${this.baseurl}document/projectdevdocumenttemplate/updateassesmenttemplate?id=${id}`, updateData, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          console.log("Template updated successfully.");
        }
      } catch (error) {
        console.error("Error updating assessment template:", error);
      }
    }
  }
};
