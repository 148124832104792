import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";

export default {
  props: {
    refreshFlag: Boolean // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    }
  },
  data() {
    return {
      assessmentData: null, 
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      idtoken: "",
      totalPages: 0,
      currentPage: 1,
      pageSize: 10, 
      pageIndex: 1 ,
      sortProperty: "createdAt",
      sortorder: 1,
      userId: null,
      showSidePopup: [],
    };
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    try {
        const decodedToken = jwt_decode(this.idtoken);
        this.userId = decodedToken.user_id;
        window.addEventListener('refreshPage', () => {
          console.log('Page refresh triggered');
          location.reload();
      });
      await this.getAsesmentList();

    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  },
  methods: {
   
    async getAsesmentList(pageIndex, pageSize) {
      try {
        const response = await axios.get(
          `${this.baseurl}document/projectdevdocumenttemplate/getasesmentlist?Id=${this.userId}&tab=MyAssessment&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
          // `https://localhost:5001/projectdevdocumenttemplate/getasesmentlist?Id=${this.userId}&tab=MyAssessment&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,

          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        this.totalPages = Math.ceil(response.data.totalCount / this.pageSize);
    
       
        this.assessmentData = response.data.entities;
    
   
this.assessmentData.forEach(assessment => {
  const dateOfReview = new Date(assessment.item.empdetailsTable.dateOfReview);
  const day = dateOfReview.getDate().toString().padStart(2, '0');
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const month = monthNames[dateOfReview.getMonth()];
  const year = dateOfReview.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  assessment.item.empdetailsTable.dateOfReview = formattedDate;
});

        
      } catch (error) {
        console.error("Error fetching assessment data:", error);
        this.assessmentData = null;
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },
    editAssessment(assessmentId) {
        window.location.href = '/empAssesment/' + assessmentId;
    },
    
      deleteAssessment(assessmentId) {
        console.log("Deleting assessment with ID:", assessmentId);
      },
      openPopup(id) {
        console.log("opening assessment with ID:", id);
      },
      async downloadAssessment(assessmentId) {
        console.log("token", this.idtoken);
        try {
          const response = await axios.post(
            `${this.baseurl}document/projectdevdocumenttemplate/generateasessment?Id=${assessmentId}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
              responseType: "blob",
            }
          );
          console.log("token2", this.idtoken);
      
          let filename = "assessment_document.pdf";
          const contentDisposition = response.headers["content-disposition"];
      
          if (contentDisposition && contentDisposition.includes("filename=")) {
            filename = contentDisposition
              .split("filename=")[1]
              .split(";")[0]
              .replace(/"/g, "");
          }
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading assessment document:", error);
          alert("Error downloading assessment document. Please try again.");
        }
      },
      
  },
};